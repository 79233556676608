@import "css-variables/breakpoints";
@import "css-variables/colors";

.fullscreenBottom {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wrapper {
  overflow: hidden;
  padding: 10px;
}

.thumbnails {
  display: inline-flex;
  transition: transform .5s ease-in-out;
}

.thumbnail {
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 5px;

  @include media-breakpoint-down(sm) {
    height: 50px;
    width: 50px;
  }
  @include media-breakpoint-up(md) {
    height: 70px;
    width: 70px;
  }
}

.image {
  pointer-events: none;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: 0 0 0 3px $white;
  }

  &.isActive {
    box-shadow: 0 0 0 3px $green;
  }
}

