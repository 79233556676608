@import "css-variables/colors";

.wrapper {
  bottom: 0;
  cursor: auto;
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 100%;
}

.container {
  transition: transform .5s ease-in-out;
}

.dot {
  background-color: $white;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 6px;
  margin: 3px 5px;
  transition: background-color .2s, box-shadow .2s;
  width: 6px;

  &:hover {
    background-color: $green;
  }

  &.isActive {
    background-color: transparent;
    box-shadow: 0 0 0 3px $white;
  }
}
