@import "../colors";

@mixin greenMarkerBaseList {
  list-style: none;

  li {
    margin-bottom: 20px;
    padding-left: 21px;
    position: relative;

    &:before {
      color: $green;
      content: "\2022";
      font-size: 18px;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}

@mixin greenMarkerList {
  @include greenMarkerBaseList;

  color: $grey50;
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;

  li {
    > ul {
      margin-top: 10px;

      > li {
        margin-bottom: 15px;

        &:before {
          color: $grey;
          content: "\25E6";
        }
      }
    }
  }
}
