@import "css-variables/colors";
@import "css-variables/fonts";
@import "css-variables/mixins/image";

.container {
  background-color: $grey11;
  color: $white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.image {
  @include responsiveImage;

  left: 0;
  opacity: .25;
  position: absolute;
  top: 0;
  z-index: -1;
}

.title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-left: 2px;
}

.quarter {
  @include font-helvetica-bdcn;

  font-size: 40px;
}

.label {
  align-self: flex-end;
  font-weight: 700;
  margin-top: auto;
}
