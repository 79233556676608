@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding: 65px 20px 95px;
  }
  @include media-breakpoint-only(sm) {
    padding: 90px 105px 100px;
  }
  @include media-breakpoint-only(md) {
    padding: 100px 160px 105px;
  }
  @include media-breakpoint-only(lg) {
    padding: 100px 105px 160px;
  }
  @include media-breakpoint-up(xl) {
    padding: 100px 120px 120px;
  }
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-down(xs) {
    height: 85px;
    width: 108px;
  }
  @include media-breakpoint-only(sm) {
    height: 109px;
    width: 144px;
  }
  @include media-breakpoint-up(md) {
    height: 115px;
    width: 147px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 35px;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 17px;
  }
  @include media-breakpoint-up(md) {
    margin-left: 19px;
  }
}

.title {
  color: $grey11;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-size: 22px;
    line-height: 32px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 44px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
}

.subtitle {
  color: $grey55;
  font-size: 14px;
  line-height: 22px;
}
