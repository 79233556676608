@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/dropdown";

.container {
  position: relative;

  @include media-breakpoint-between(sm, md) {
    width: 210px;
  }
  @include media-breakpoint-only(lg) {
    width: 297px;
  }
  @include media-breakpoint-up(xl) {
    width: 320px;
  }
}

.button {
  align-items: center;
  background-color: $white;
  border: none;
  border-radius: 2px;
  color: $grey50;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding: 12px 15px;
  position: relative;
  text-overflow: ellipsis;
  transition: color .2s;
  white-space: nowrap;
  width: 100%;

  &:hover,
  &:focus-visible {
    color: $grey;

    .arrowIcon {
      color: $secondaryDark;
    }
  }
}

.arrowIcon {
  color: $secondary;
  height: 12px;
  margin-left: auto;
  transform: rotate(270deg);
  transition: color .2s;
  width: 8px;
}

.dropdown {
  @include dropdown;

  border-radius: 2px;
  box-shadow: 0 0 15px $transparentBlack15, 0 0 2px $transparentBlack15;
  max-height: 300px;
  width: 100%;
}

.dropdownItem {
  @include dropdownItem;

  color: $grey50;
  min-height: 40px;
  padding: 0 14px;

  &.isSelected {
    font-weight: 400 !important;
  }
}

.tickIcon {
  @include dropdownItemTickIcon;

  height: 10px;
  margin-right: -2px;
  width: 14px;
}
