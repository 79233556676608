@import "css-variables/breakpoints";
@import "css-variables/colors";

.overlay {
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.container {
  background-color: $white;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .1);
  color: $grey11;
  margin: auto;

  @include media-breakpoint-down(xs) {
    height: 100%;
    width: 100%;
  }
  @include media-breakpoint-up(sm) {
    width: 650px;
  }
}

.header {
  align-items: center;
  background-color: $grey96;
  border-bottom: 1px solid $grey96;
  border-bottom: 1px $grey80 solid;
  display: flex;
  padding: 10px;
}

.logo {
  height: 40px;
  width: 105px;
}

.title {
  color: $grey;
  font-size: 18px;
  font-weight: 700;
  margin-left: 4%;
}

.closeButton {
  background-color: $grey80;
  border: 2px solid $grey40;
  border-radius: 50%;
  color: $grey40;
  cursor: pointer;
  flex-shrink: 0;
  height: 24px;
  margin-left: auto;
  padding: 0;
  transition: color .2s, border-color .2s, background-color .2s;
  width: 24px;

  &:hover {
    background-color: $grey85;
    border-color: $grey;
    color: $grey;
  }
}

.closeButtonIcon {
  height: 10px;
  width: 10px;
}
