@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  color: $grey50;
  font-size: 14px;
  line-height: 22px;
  margin-top: 70px;
}

.containerWithoutImage {
  border-top: 1px solid $grey92;

  @include media-breakpoint-down(xs) {
    padding-top: 28px;
  }
  @include media-breakpoint-only(sm) {
    padding-top: 34px;
  }
  @include media-breakpoint-only(md) {
    padding-top: 40px;
  }
  @include media-breakpoint-only(lg) {
    padding-top: 53px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 60px;
  }
}

.photoWrapper {
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-only(lg) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 30px;
  }
}

.photo {
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(lg) {
    height: 100px;
    width: 100px;
  }
  @include media-breakpoint-up(xl) {
    height: 120px;
    width: 120px;
  }

  &.isGrey {
    background-color: $grey50;
    border: solid 1px $grey50;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .5);
  }

  &.isWhite {
    background-color: $white;
    border: solid 1px $grey92;
    box-shadow: 0 2px 3px 0 $transparentBlack15;
  }
}
