@import "css-variables/colors";

.container {
  position: relative;
}

.input {
  padding-right: 50px;
}

.toggleButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
}

.iconOpen {
  color: $grey61;
}

.iconClose {
  color: $grey85;
}
