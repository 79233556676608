@import "css-variables/breakpoints";
@import "css-variables/colors";

.overlay {
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding-top: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &::after { // FF and IE fix of padding-bottom in scrollable block
    content: '';
    display: block;
    flex: 0 0 auto;
    padding-bottom: 20px;
  }
}

.container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .1);
  flex: 0 0 auto;
  margin: auto;

  @include media-breakpoint-down(xs) {
    width: 335px;
  }
  @include media-breakpoint-only(sm) {
    width: 500px;
  }
  @include media-breakpoint-only(md) {
    width: 778px;
  }
  @include media-breakpoint-only(lg) {
    width: 992px;
  }
  @include media-breakpoint-up(xl) {
    width: 1160px;
  }
}

.container.containerSingleColumn {
  @include media-breakpoint-only(md) {
    width: 616px;
  }
  @include media-breakpoint-only(lg) {
    width: 581px;
  }
  @include media-breakpoint-up(xl) {
    width: 680px;
  }
}

.title {
  align-items: center;
  border-bottom: 1px solid $grey96;
  box-sizing: content-box;
  color: $grey;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 26px;
  height: 60px;

  @include media-breakpoint-down(xs) {
    padding-left: 25px;
  }

  @include media-breakpoint-down(sm) {
    height: 60px;
  }
  @include media-breakpoint-up(md) {
    height: 70px;
  }
}

.closeButton {
  background: transparent;
  border: none;
  color: $grey50;
  cursor: pointer;
  display: flex;
  height: 14px;
  outline: none;
  padding: 2px 0 0;
  width: 14px;

  &:hover {
    color: $grey;
  }
}
