@import "css-variables/mixins/form";

.container {
  position: relative;
  height: 137px;
}

.textarea {
  @include formField;

  padding: 15px;
  resize: none;
}
