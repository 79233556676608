@import "css-variables/colors";
@import "css-variables/breakpoints";

.privacyTextContainer {
  color: $grey55;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 35px;

  a {
    color: $grey11;

    &:hover {
      color: $green;
    }
  }
}
