@import "css-variables/colors";
@import "./reset";

:root {
  --warning-bar-height: 0px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html, body {
  overflow-x: hidden;
}

body {
  color: $grey;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.29;
  scroll-behavior: smooth;
}

.scrollLock {
  overflow: hidden;
}

a {
  color: $green;
  text-decoration: none;
  transition: color .2s;
}
