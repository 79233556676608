@import "css-variables/breakpoints";
@import "css-variables/colors";

.serverError {
  background-color: $red;
  color: $white;
  text-align: center;

  @include media-breakpoint-down(md) {
    line-height: 22px;
    font-size: 14px;
    padding: 19px 0;
  }

  @include media-breakpoint-between(md, lg) {
    padding: 18px 0;
  }

  @include media-breakpoint-up(md) {
    line-height: 24px;
    font-size: 16px;
  }

  @include media-breakpoint-only(lg) {
    font-weight: 700;
  }

  @include media-breakpoint-up(lg) {
    padding: 28px 0;
  }
}
