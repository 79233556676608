@import "css-variables/colors";

.defaultButton {
  align-items: center;
  background-color: $secondary;
  border: none;
  border-radius: 25px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 40px;
  justify-content: center;
  letter-spacing: .7px;
  line-height: 14px;
  padding: 0 25px;
  position: relative;
  text-align: center;
  transition: background-color .2s;
  user-select: none;
  width: auto;

  &:hover,
  &.isDisabled {
    background-color: $grey;
  }

  &.isDisabled {
    cursor: auto;
    pointer-events: none;
  }

  &.withLeftArrow {
    padding-left: 48px;
  }
  &.withRightArrow,
  &.withTopArrow,
  &.withBottomArrow {
    padding-right: 48px;
  }
  &.withoutText {
    width: 40px;
  }

  .arrowIcon {
    &.left {
      left: 25px;
    }
    &.right {
      right: 25px;
    }
  }
}

.button {
  align-items: center;
  border: none;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  position: relative;
  transition: color .2s, background-color .2s, box-shadow .2s;

  &:not([disabled]) {
    cursor: pointer;
  }
  &.isInProcess {
    cursor: wait;
  }
  &.isDisabled {
    cursor: not-allowed;
  }
}

.small {
  border-radius: 16px;
  font-size: 12px;
  height: 32px;
  line-height: 14px;
  padding-left: 16px;
  padding-right: 16px;

  &.withLeftArrow {
    padding-left: 26px;
  }
  &.withRightArrow,
  &.withTopArrow,
  &.withBottomArrow {
    padding-right: 26px;
  }
  &.withoutText {
    width: 32px;
  }
}

.medium {
  border-radius: 20px;
  font-size: 14px;
  height: 40px;
  line-height: 16px;
  padding-left: 24px;
  padding-right: 24px;

  &.withLeftArrow {
    padding-left: 38px;
  }
  &.withRightArrow,
  &.withTopArrow,
  &.withBottomArrow {
    padding-right: 38px;
  }
  &.withoutText {
    width: 40px;
  }
}

.large {
  border-radius: 25px;
  font-size: 16px;
  height: 50px;
  letter-spacing: .24px;
  line-height: 18px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;

  &.withLeftArrow {
    padding-left: 50px;
  }
  &.withRightArrow,
  &.withTopArrow,
  &.withBottomArrow {
    padding-right: 50px;
  }
  &.withoutText {
    width: 50px;
  }
}

.withoutText {
  padding: 0;
}

.primary {
  background-color: $primary;
  color: $white;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      background-color: $primaryDark;
    }
  }
  &.isDisabled,
  &.isInProcess {
    background-color: $primaryLight;
  }
}

.secondary {
  background-color: $secondary;
  color: $white;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      background-color: $secondaryDark;
    }
  }
  &.isDisabled,
  &.isInProcess {
    background-color: $secondaryLight;
  }
}

.grey {
  background-color: $grey92;
  color: $grey;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      background-color: $grey50;
      color: $white;
    }
  }
  &.isDisabled,
  &.isInProcess {
    background-color: $grey96;
    color: $grey61;
  }
}

.outlined {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px $grey85; // it is used instead of border to simplify styles and prevent issues with active and hover styles
  color: $grey;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      box-shadow: inset 0 0 0 2px $grey50;
      color: $grey11;
    }
  }
  &.isDisabled,
  &.isInProcess {
    color: $grey61;
  }
}

.link,
.linkButton {
  background-color: transparent;
  color: $secondary;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      color: $secondaryDark;
    }
  }
  &.isDisabled,
  &.isInProcess {
    color: $secondaryLight;
  }
}

.link {
  height: auto;
  letter-spacing: 0;
  padding: 0;
  width: auto;

  &.withLeftArrow {
    padding-left: 21px;
  }
  &.withRightArrow {
    padding-right: 15px;
  }
  &.withTopArrow,
  &.withBottomArrow {
    padding-right: 21px;
  }

  > .arrowIcon {
    transition: color .2s;
  }

  &:hover,
  &:focus-visible,
  &.isDisabled,
  &.isInProcess {
    > .arrowIcon {
      color: inherit;
    }
  }
}

.linkButton {
  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      background-color: rgba($secondary, .16);
    }
  }
}

.filter {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px $grey92; // it is used instead of border to simplify styles and prevent issues with active and hover styles
  color: $grey50;
  font-weight: 400;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      box-shadow: inset 0 0 0 1px $grey50;
      color: $grey;
    }
  }
  &.isDisabled,
  &.isInProcess {
    color: $grey61;
  }
}

.arrowIcon {
  height: 12px;
  position: absolute;
  top: 50%;
  transition: color .2s, transform .2s;
  width: 8px;

  &.left {
    order: -1;
    transform: translateY(-50%);

    .small > & {
      left: 11px;
    }
    .medium > & {
      left: 19px;
    }
    .large > & {
      left: 23px;
    }
    .withoutText > & {
      left: 50%;
      margin-left: -.5px;
      transform: translate(-50%, -50%);
    }
  }
  &.right,
  &.top,
  &.bottom {
    .small > & {
      right: 11px;
    }
    .medium > & {
      right: 19px;
    }
    .large > & {
      right: 23px;
    }
    .withoutText > & {
      left: 50%;
      margin-left: .5px;
      right: auto;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  &.right {
    transform: translateY(-50%) rotate(180deg);
  }
  &.top {
    transform: translateY(-50%) rotate(90deg);
  }
  &.bottom {
    transform: translateY(-50%) rotate(-90deg);
  }

  .link > & {
    color: $secondary;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}

.customIcon {
  .withoutText > & {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinnerIcon {
  animation: spin 1.3s linear infinite;
  color: $green;
  margin-left: 3px;
  margin-right: -8px;

  .small > &,
  .medium > & {
    height: 16px;
    width: 16px;
  }
  .large > & {
    height: 20px;
    width: 20px;
  }

  .primary > &,
  .secondary > & {
    color: $white;
  }
}

.customIcon,
.spinnerIcon {
  &.left {
    margin-left: 0;
    margin-right: 6px;
    order: -1;
  }
  &.right {
    margin-left: 8px;
    margin-right: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
