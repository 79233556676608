@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/dimensions";

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}

.header {
  align-items: center;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: background-color .2s, box-shadow .2s;

  @include media-breakpoint-down(sm) {
    height: map-get($headerHeight, sm);
  }
  @include media-breakpoint-between(md, lg) {
    height: map-get($headerHeight, md);
  }
  @include media-breakpoint-up(xl) {
    height: map-get($headerHeight, xl);
  }

  &.isInverted {
    background-color: rgba($white, .8);
  }

  &.isActive {
    background-color: $white;
    box-shadow: 0 1px 0 $grey96;
  }
}
