@import "css-variables/colors";

.content {
  padding: 20px;
}

.text {
  line-height: 22px;
  margin-bottom: 22px;

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.closeButton {
  background-color: $lightGreen;
  border: 0;
  color: $grey20;
  cursor: pointer;
  display: block;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  transition: background-color .2s;
  width: 200px;

  &:hover {
    background-color: $green;
  }
}
