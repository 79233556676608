@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    max-height: 100%;
  }
}

.modalContent {
  display: flex;
  overflow-y: hidden;

  @include media-breakpoint-down(xs) {
    flex: 1;
    flex-direction: column;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 1 400px;
  }
}

.modalTabContent {
  overflow-y: auto;
  padding: 10px;

  @include media-breakpoint-up(sm) {
    flex: 1;
  }
}

.modalTabLabel {
  background-color: $grey92;
  border-bottom: 1px $grey80 solid;
  border-right: 1px $grey80 solid;
  cursor: pointer;
  padding: 10px;

  @include media-breakpoint-up(sm) {
    width: 200px;
  }
}

.modalTabLabelActive {
  background-color: transparent;
  border-right: none;
}

.modalFooter {
  background-color: $grey96;
  border-top: 1px $grey80 solid;
  display: flex;
  margin-top: auto;
  min-height: 56px;
  padding: 10px;
}

.modalFooterBtn {
  background-color: $lightGreen;
  border: none;
  cursor: pointer;
  font-weight: 700;
  margin-right: 10px;
  outline: none;
  padding: 7px;
  transition: background-color .2s;

  &:hover {
    background-color: $green;
  }

  &:first-child {
    margin-left: auto;
    width: 72px;
  }

  &:last-child {
    width: 122px;
  }
}

.modalTabContentHeader {
  border-bottom: 1px $grey80 solid;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.modalTabContentTitle {
  font-weight: 700;
}

.modalTabContentSwitchWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.modalTabContentDescription {
  div {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.modalTabContentCookiesListTitle {
  border-bottom: 1px $grey80 solid;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 0;
}

.modalTabContentCookiesList {
  padding: 5px 0;
}

.modalTabContentCookie {
  color: $grey11;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    color: $green;
    text-decoration: none;
  }
}

.switchCheckBoxLabel {
  color: $grey61;
  font-weight: 700;
  margin-right: 5px;
}

.switchCheckBox {
  background-color: $grey61;
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 35px;

  &::after {
    background-color: $white;
    border-radius: 50%;
    content: "";
    height: 16px;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: right .2s;
    width: 16px;
  }
}

.modalTabContentSwitchOn {
  .switchCheckBoxLabel {
    color: $lightGreen;
  }

  .switchCheckBox {
    background-color: $lightGreen;

    &::after {
      right: 17px;
    }
  }
}
