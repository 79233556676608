@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding: 120px 20px 150px;
  }
  @include media-breakpoint-only(sm) {
    padding: 100px 50px 160px;
  }
  @include media-breakpoint-only(md) {
    padding: 100px 80px 150px;
  }
  @include media-breakpoint-only(lg) {
    padding: 100px 105px 150px;
  }
  @include media-breakpoint-up(xl) {
    padding: 160px 120px;
  }
}

.icon {
  align-items: center;
  background-color: $green;
  border-radius: 50%;
  color: $white;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0 auto 30px;
  width: 48px;
}

.iconTick {
  height: 19px;
  width: 25px;
}

.title {
  color: $grey11;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 20px;
  }
}

.subtitle {
  color: $grey55;
  font-size: 14px;
  line-height: 18px;
}
