@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/image";

.sectionTitle {
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px;
    margin-top: 70px;
  }
  @include media-breakpoint-only(sm) {
    margin-top: 57px;
  }
  @include media-breakpoint-between(sm, md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-only(md) {
    margin-top: 90px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: 80px;
  }
  @include media-breakpoint-up(lg) {
    color: $grey11;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 22px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 150px;
  }
}

.container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 10px 12px 23px 0 $transparentBlack15;
  overflow: hidden;
  transition: box-shadow .2s;

  @include media-breakpoint-down(xs) {
    display: block;
    margin-bottom: 25px;
  }
  @include media-breakpoint-up(sm) {
    align-items: center;
    display: flex;
    margin-bottom: 45px;
  }

  &:hover {
    box-shadow: 5px 6px 11px 0 rgba(0, 0, 0, .1);
  }
}

.imageWrapper {
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(xs) {
    height: 200px;
  }
  @include media-breakpoint-up(sm) {
    align-self: stretch;
  }

  @include media-breakpoint-only(sm) {
    width: 135px;
  }
  @include media-breakpoint-only(md) {
    width: 130px;
  }
  @include media-breakpoint-only(lg) {
    width: 168px;
  }
  @include media-breakpoint-up(lg) {
    width: 200px;
  }

  @include media-breakpoint-between(sm, md) {
    min-height: 130px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 155px;
  }
}

.image {
  @include responsiveImage;

  position: absolute;
}

.title {
  color: $grey;
  flex: 1;
  font-weight: 700;
  padding-bottom: 19px;
  padding-top: 18px;

  @include media-breakpoint-down(xs) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @include media-breakpoint-only(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-only(md) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include media-breakpoint-only(lg) {
    padding-left: 38px;
    padding-right: 38px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 1.57;
  }
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.75;
  }
}

.arrowWrapper {
  @include media-breakpoint-down(xs) {
    display: none;
  }
  @include media-breakpoint-only(sm) {
    padding-right: 20px;
    width: 15%;
  }
  @include media-breakpoint-up(md) {
    width: 12%;
  }
  @include media-breakpoint-only(md) {
    padding-right: 30px;
  }
  @include media-breakpoint-only(lg) {
    padding-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 30px;
  }
}

.arrowIcon {
  color: $green;
  display: block;
  height: 38px;
  margin-left: auto;
  transform: rotate(180deg);
  width: 23px;
}
