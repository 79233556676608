@import "css-variables/breakpoints";
@import "css-variables/colors";

.banner {
  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }
  @include media-breakpoint-down(xs) {
    padding: 60px 25px;
  }
  @include media-breakpoint-between(sm, md) {
    padding: 20px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: 40px;
    padding: 20px 30px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 50px;
    padding: 20px 85px;
  }

  &.isSingle {
    box-shadow: 10px 12px 23px 0 $transparentBlack15;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;

    @include media-breakpoint-down(xs) {
      max-width: 335px;
      min-height: 415px;
    }
    @include media-breakpoint-between(sm, md) {
      max-width: 290px;
      min-height: 385px;
    }
    @include media-breakpoint-only(lg) {
      max-width: 375px;
      min-height: 442px;;
    }
    @include media-breakpoint-up(xl) {
      max-width: 440px;
      min-height: 419px;;
    }
  }
}
