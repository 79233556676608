@import "../colors";

@mixin formField {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1.29;
  outline: none;
  padding: 0 22px 0 15px;
  transition: border-color .2s, box-shadow .2s;
  width: 100%;
  height: 100%;

  &::placeholder {
    color: $grey50;
  }
}
