@import "css-variables/colors";
@import "css-variables/breakpoints";

.container {
  background-color: $white;
  cursor: pointer;
  transition: box-shadow .2s, margin .1s, padding .1s;

  @include media-breakpoint-down(sm) {
    border-bottom: 2px solid $grey96;
  }
  @include media-breakpoint-up(md) {
    border: 1px solid $white;
    box-sizing: content-box;
    height: 100%;
    position: relative;
  }
  @include media-breakpoint-up(lg) {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    @include media-breakpoint-up(md) {
      border-color: $grey96;
      box-shadow: 10px 12px 23px 0 $transparentBlack15;
      z-index: 1;
    }
    @include media-breakpoint-up(lg) {
      box-shadow: 29px 30px 18px 0 $transparentBlack13;
    }
    @include media-breakpoint-only(md) {
      margin-bottom: -20px;
      margin-top: -20px;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    @include media-breakpoint-only(lg) {
      margin-bottom: -50px;
      margin-top: -50px;
      padding-bottom: 50px;
      padding-top: 50px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: -40px;
      margin-top: -40px;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
}

.linkContainer {
  display: block;
}

.innerContainer {
  @include media-breakpoint-down(xs) {
    padding: 40px 0;
  }
  @include media-breakpoint-only(sm) {
    padding: 30px 50px 29px;
  }
  @include media-breakpoint-only(md) {
    padding: 49px 35px;
  }
  @include media-breakpoint-only(lg) {
    padding: 64px 50px;
  }
  @include media-breakpoint-up(xl) {
    padding: 69px 50px;
  }
}

.titleWrapper {
  white-space: nowrap;

  &::after {
    background-color: $green;
    content: '';
    display: block;
    height: 4px;
    margin-top: 13px;
    width: 57px;

    @include media-breakpoint-only(sm) {
      width: 83px;
    }
  }
}

.title {
  color: $grey11;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  transition: color .2s;
  white-space: normal;

  .container:hover & {
    color: $green;
  }
}

.chevron {
  color: $green;
  margin-left: 2px;
  transition: transform .2s;

  .anchorContainer:hover & {
    transform: rotate(90deg);
  }
}

.description {
  color: $grey55;
  line-height: 22px;
  margin-top: 18px;

  @include media-breakpoint-only(sm) {
    margin-top: 14px;
  }
}
