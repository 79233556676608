@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  display: flex;

  @include media-breakpoint-down(xs) {
    margin-bottom: 70px;
  }
  @include media-breakpoint-only(sm) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 120px;
  }
  @include media-breakpoint-only(lg) {
    margin-bottom: 110px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 160px;
    justify-content: center;
  }

  &.reversed {
    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
    }
  }
}

.textBlock {
  align-self: center;

  @include media-breakpoint-up(xl) {
    max-width: 720px;
  }
}

.title {
  color: $grey11;
  font-weight: 700;
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    font-size: 22px;
    line-height: 32px;
  }
  @include media-breakpoint-only(md) {
    font-size: 32px;
    line-height: 44px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 55px;
    line-height: 64px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 20px;
    width: 375px;
  }

  &.secondary {
    @include media-breakpoint-only(lg) {
      font-size: 32px;
      line-height: 44px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 42px;
      line-height: 64px;
    }
  }
}

.description {
  color: $grey;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  white-space: pre-line;

  @include media-breakpoint-up(xl) {
    padding-bottom: 5px;
  }

  a {
    &:hover {
      color: $grey;
    }
  }
}

.button {
  height: 50px;
  padding: 0 15px;

  @include media-breakpoint-down(xs) {
    min-width: 215px;
  }
  @include media-breakpoint-only(sm) {
    min-width: 185px;
  }
  @include media-breakpoint-only(md) {
    min-width: 210px;
  }
  @include media-breakpoint-only(lg) {
    min-width: 270px;
  }
  @include media-breakpoint-up(xl) {
    min-width: 205px;
  }
}

.containerWithCards {
  @include media-breakpoint-down(sm) {
    padding-bottom: 59px;
    position: relative;
  }

  .textBlock {
    @include media-breakpoint-only(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .button {
    @include media-breakpoint-down(sm) {
      bottom: 0;
      position: absolute;
    }
  }
}
