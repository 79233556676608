// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. Prevent adjustments of font size after orientation changes in iOS.
// 3. Change the default tap highlight to be completely transparent in iOS.

body {
  margin: 0; // 1
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 3
  -webkit-text-size-adjust: 100%; // 2
}

// Typography
//
// 1. Remove margins from headings

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  margin: 0;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
  margin-bottom: 15px;
  margin-top: 0;
}

// Lists

dd, dl, li, ol, ul {
  margin: 0;
  padding: 0
}

ol, ul {
  list-style: none outside none;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
  font-weight: 700;
}

// Images and content

img,
svg {
  vertical-align: middle;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
  display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.

button {
  border-radius: 0;
}

a,
button {
  outline: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; // 1
  outline: 0;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input,
button {
  overflow: visible;
}

/**
 * Hide the cross and eye icons in inputs in Edge 18- and IE.
 */

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// Remove the inheritance of text transform in Firefox
button,
select {
  text-transform: none;
}

// Set the cursor for non-`<button>` buttons
[role="button"] {
  cursor: pointer;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2

  &:not(:disabled) {
    cursor: pointer; // 3
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: none;
}

input,
textarea {
  &::placeholder {
    opacity: 1;
  }
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
  border: 0; // 2
  margin: 0; // 2
  min-width: 0; // 1
  padding: 0; // 2
}

// Remove border from iframe

iframe {
  border: 0;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
  display: none !important;
}
