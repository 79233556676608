@import "css-variables/colors";

.border {
  border: solid 1px $grey85;
  border-radius: 2px;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 37px;
  }
}

.borderInvalid {
  border-bottom: 2px solid $errorRed;
}

.label {
  color: $placeholderGrey;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  transition: all .4s ease-in-out;
  pointer-events:none;
  font-size: 14px;
  line-height: 20px;
  user-select: none;

  &.labelFocused {
    top: -6px;
    left: 13px;
    bottom: auto;
    font-size: 12px;
    line-height: 12px;
    padding: 0 2px;
    color: #99ba14;
    background-color: #fff;
  }
}
