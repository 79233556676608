@import "../colors";

%link {
  cursor: pointer;
  transition: color .2s;

  &:hover,
  &:focus-visible {
    color: $secondaryDark;
  }
}

%secondaryLink {
  @extend %link;

  color: $secondary;
}

%greyLink {
  @extend %link;

  color: $grey;
}

%darkGreyLink {
  @extend %link;

  color: $grey11;
}

%lightGreyLink {
  @extend %link;

  color: $grey50;
}

%whiteLink {
  @extend %link;

  color: $white;
}

%greyIcon {
  color: $grey50;
  cursor: pointer;
  transition: color .2s;

  &:hover,
  &:focus-visible {
    color: $grey;
  }
}
