@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/links";

.button {
  @extend %greyIcon;

  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: color .2s;
  width: 72px;

  @include media-breakpoint-down(sm) {
    width: 40px;
  }
  @include media-breakpoint-up(md) {
    width: 72px;
  }

  &.isNotInverted {
    color: $white;
  }

  &.isFocused {
    color: $grey;
  }

  &.isActive {
    @extend %secondaryLink;

    &.isFocused {
      color: $secondaryDark;
    }
  }
}

.icon {
  height: 22px;
  width: 22px;
}

.tooltip {
  background-color: $primary;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba($darkBlue, .08);
  color: $white;
  cursor: auto;
  font-size: 14px;
  height: 34px;
  line-height: 18px;
  opacity: 0;
  padding: 8px;
  position: absolute;
  text-align: center;
  transform: scale(0);
  transform-origin: 100% 0;
  transition: opacity .2s, transform .3s;
  visibility: hidden;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    margin-top: 12px;
    right: 3px;
    top: 100%;
  }
  @include media-breakpoint-up(md) {
    bottom: -30px;
    right: 20px;
  }

  &:before {
    background-color: $primary;
    border-radius: 2px;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    right: 12px;
    top: -4px;
    transform: rotate(45deg);
    width: 10px;
    z-index: -1;
  }

  .button:hover > & {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
}
