@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/lists";

.articleContentContainer {
  overflow-wrap: break-word;

  a,
  .body-link {
    text-decoration: underline;

    &:hover {
      color: $grey;
      text-decoration: none;
    }
  }

  > ul,
  .green-bullet-list {
    @include greenMarkerList;
  }

  table {
    border: 1px solid $grey50;
    margin-bottom: 20px;
    width: 100%;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }

    th, td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .section-title {
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 50px;
  }

  .quote {
    border-left: 5px solid $green;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 19px;
  }

  .source,
  .quote-author {
    color: $grey50;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 9px;
  }

  .iframe-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
