@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  align-items: center;
  background-color: $grey96;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    height: 70px;
  }
  @include media-breakpoint-up(lg) {
    height: 80px;
  }
}

.listingLink {
  color: $grey;
  font-weight: 700;
  padding-left: 16px;
  position: relative;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 28px;
  }

  &:hover {
    color: $green;
  }
}

.linkChevron {
  color: $green;
  height: 12px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 8px;
}
