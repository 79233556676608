@import "css-variables/colors";

.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  transition: color .2s;
  width: 70px;
}

.buttonLeft {
  left: 0;
}

.buttonRight {
  right: 0;

  > .icon {
    transform: rotate(180deg);
  }
}

.icon {
  color: $white;
  height: 36px;
  transition: color .2s;
  width: 22px;

  .button:hover > & {
    color: $green;
  }
}
