@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  bottom: -10px;
  box-shadow: 14px 38px 23px 0 $transparentBlack13;
  position: absolute;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    right: 0;
    width: 205px;
  }
  @include media-breakpoint-only(md) {
    right: 5px;
    width: 245px;
  }
  @include media-breakpoint-up(lg) {
    right: 10px;
  }
  @include media-breakpoint-only(lg) {
    width: 230px;
  }
  @include media-breakpoint-up(xl) {
    width: 340px;
  }
}

.isReversed {
  right: auto;

  @include media-breakpoint-down(sm) {
    left: 0;
  }
  @include media-breakpoint-only(md) {
    left: 5px;
  }
  @include media-breakpoint-up(lg) {
    left: 10px;
  }
}

.poster,
.image {
  @include media-breakpoint-down(xs) {
    height: 240px;
  }
  @include media-breakpoint-only(sm) {
    height: 260px;
  }
  @include media-breakpoint-only(md) {
    height: 300px;
  }
  @include media-breakpoint-only(lg) {
    height: 270px;
  }
  @include media-breakpoint-up(xl) {
    height: 400px;
  }
}

.poster {
  @include media-breakpoint-between(md, lg) {
    padding: 25px;
  }
  @include media-breakpoint-up(xl) {
    padding: 30px;
  }
}

.image {
  object-fit: cover;
}

.marketReport {
  background-color: $grey;

  img {
    opacity: .42;
  }
}

.marketReportTitle {
  margin-left: 0;
}

.marketReportTitle,
.marketReportLabel {
  @include media-breakpoint-down(md) {
    line-height: 22px;
  }
  @include media-breakpoint-only(lg) {
    font-size: 12px;
    line-height: 15px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 16px;
  }
}

.marketReportQuarter {
  @include media-breakpoint-up(sm) {
    letter-spacing: 1px;
    line-height: 1;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 48px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 52px;
  }
}

.industry {
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
  @include media-breakpoint-between(md, lg) {
    padding: 25px;
  }
  @include media-breakpoint-up(xl) {
    padding: 25px 30px 30px;
  }
}

.industryTitle {
  @include media-breakpoint-down(md) {
    line-height: 22px;
  }
  @include media-breakpoint-only(lg) {
    font-size: 12px;
    line-height: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 28px;
  }
}

.industryLabel {
  @include media-breakpoint-down(md) {
    letter-spacing: 1.2px;
  }
  @include media-breakpoint-up(lg) {
    letter-spacing: 1px;
  }

  @include media-breakpoint-only(lg) {
    margin-right: 10px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 26px;
    line-height: 1;
    margin-right: 5px;
  }
}

.banner {
  border: 1px solid $grey96;

  @include media-breakpoint-down(md) {
    padding: 20px;
  }
  @include media-breakpoint-up(lg) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 30px;
  }

  @include media-breakpoint-down(sm) {
    height: 260px;
  }
  @include media-breakpoint-only(sm) {
    width: 205px;
  }
  @include media-breakpoint-only(md) {
    height: 280px;
    width: 210px;
  }
  @include media-breakpoint-only(lg) {
    height: 290px;
    width: 270px;
  }
  @include media-breakpoint-up(xl) {
    height: 400px;
  }
}

.bannerImage {
  @include media-breakpoint-down(xs) {
    height: 80px;
    width: 80px;
  }
  @include media-breakpoint-only(sm) {
    height: 85px;
    width: 85px;
  }
  @include media-breakpoint-up(md) {
    height: 100px;
    width: 100px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 15px;
  }
  @include media-breakpoint-between(sm, md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-only(lg) {
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 30px;
  }
}

.bannerTitle {
  @include media-breakpoint-down(lg) {
    font-size: 14px;
    line-height: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 22px;
    line-height: 32px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 10px;
    text-align: center;
  }

  @include media-breakpoint-only(lg) {
    width: 120px;
  }
  @include media-breakpoint-up(xl) {
    width: 100%;
  }
}

.bannerDescription {
  margin-bottom: 0;

  @include media-breakpoint-only(lg) {
    font-size: 12px;
    line-height: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 28px;
    padding: 0 25px;
  }
}
