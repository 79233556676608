/**
 * @license
 * MyFonts Webfont Build ID 3810215, 2019-09-23T10:02:31-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 *
 * Webfont: HelveticaLTWXX-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-bold-174619/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3810215
 * Licensed pageviews: 15,000,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

/* @import url("//hello.myfonts.net/count/3a23a7"); */

@font-face {
  font-family: 'Helvetica';
  src: url('./3A23A7_0_0.woff2') format('woff2'), url('./3A23A7_0_0.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./3A23A7_1_0.woff2') format('woff2'), url('./3A23A7_1_0.woff') format('woff');
  font-weight: 700;
}

/* todo: move tracking to script */
/*@import url("//hello.myfonts.net/count/34da0c");*/

@font-face {
  font-family: 'HelveticaNeueLTStd-BdCn';
  src: url('./34DA0C_3_0.woff2') format('woff2'), url('./34DA0C_3_0.woff') format('woff');
}
