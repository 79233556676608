@import "css-variables/colors";
@import "css-variables/mixins/form";

.container {
  font-size: 14px;
  line-height: 1.29;
  position: relative;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 37px;
  }
}

.wrapper {
  height: 50px;
}

.input {
  @include formField;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.inputNoValue {
    color: $grey50;
    flex-direction: row-reverse;
  }

  &.isInvalid {
    .inputTick {
      color: $errorRed;
    }
  }
}

.inputTick {
  color: $green;

  &.inputTickTurned {
    transform: rotateZ(180deg);
  }
}

.list {
  width: 100%;
  height: auto;
  max-height: 250px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 51px;
  border-radius: 4px;
  box-shadow: 0 0 15px $transparentBlack15, 0 0 2px $transparentBlack15;
  background-color: $white;
  overflow: auto;
  z-index: 100;
}

.listItem {
  width: 100%;
  height: 50px;
  cursor: pointer;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $grey50;

  &_focused, &:hover {
    background-color: $grey92;
    color: $grey11;
  };
}

.tick {
  color: $green;
}
