@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/fonts";
@import "css-variables/mixins/image";

.container {
  background-color: $grey11;
  color: $white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: relative;
  z-index: 1;
}

.image {
  @include responsiveImage;

  left: 0;
  opacity: .25;
  position: absolute;
  top: 0;
  z-index: -1;
}

.title {
  font-weight: 700;
}

.label {
  @include font-helvetica-bdcn;

  align-self: flex-end;
  line-height: .88;
  margin-top: auto;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    font-size: 27.6px;
    letter-spacing: .6px;
  }
  @include media-breakpoint-only(md) {
    font-size: 21.5px;
    letter-spacing: .47px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 27.6px;
    letter-spacing: .6px;
  }
}
