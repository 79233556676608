@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/dimensions";

.container {
  background-color: $grey;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    padding-top: map-get($headerWrapperHeight, sm);
  }
  @include media-breakpoint-between(md, lg) {
    padding-top: map-get($headerWrapperHeight, md);
  }
  @include media-breakpoint-up(xl) {
    padding-top: map-get($headerWrapperHeight, xl);
  }
}
