@import "css-variables/breakpoints";

.contentContainer {
  @include media-breakpoint-down(xs) {
    padding-bottom: 50px;
  }
  @include media-breakpoint-between(sm, md) {
    padding-bottom: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 50px;
  }
}
