.wrapper {
  height: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  height: 100%;
  transition: transform .5s ease-in-out;
}
