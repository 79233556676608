@import "css-variables/breakpoints";

.link {
  display: block;
  margin-right: auto;
}

.image {
  display: block;

  @include media-breakpoint-down(xs) {
    width: 79px;
  }
  @include media-breakpoint-only(sm) {
    width: 103px;
  }
  @include media-breakpoint-up(md) {
    width: 130px;
  }
}
