@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/parallax";

.container {
  @include parallaxHeader;
  @include parallaxHeaderPadding;
}

.image {
  left: 0;
  opacity: .28;
  position: absolute;
  top: 0;
}

.rowWrapper {
  align-items: center;
  height: 100%;
}

.textContainer {
  color: $white;
  position: relative;
}

.title {
  font-weight: 700;
  line-height: 1.4;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 1.38;
  }
  @include media-breakpoint-only(md) {
    font-size: 50px;
  }
  @include media-breakpoint-only(lg) {
    font-size: 60px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 70px;
  }
}

.description {
  @include media-breakpoint-down(xs) {
    line-height: 1.75;
    margin-top: 20px;
  }
  @include media-breakpoint-between(sm, lg) {
    line-height: 1.5;
    margin-top: 22px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 1.7;
    margin-top: 35px;
  }
}
