@import "css-variables/colors";
@import "css-variables/breakpoints";

@mixin sidePadding($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

%fieldset {
  @include media-breakpoint-down(xs) {
    @include sidePadding(25px);
  }
  @include media-breakpoint-only(sm) {
    @include sidePadding(52px);
  }
  @include media-breakpoint-only(md) {
    @include sidePadding(81px);
  }
  @include media-breakpoint-only(lg) {
    @include sidePadding(103px);
  }
  @include media-breakpoint-up(xl) {
    @include sidePadding(120px);
  }
}

.subtitleSection {
  @extend %fieldset;

  background-color: $grey96;
  color: $grey50;
  font-size: 14px;
  line-height: 22px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    line-height: 20px;
  }
}

.mainFieldsetHeadline {
  color: $grey;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  padding-top: 36px;
  text-align: center;
}

.fieldsContainer {
  @extend %fieldset;
  margin-top: 36px;
  margin-bottom: 41px;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.column {
  @include media-breakpoint-down(sm) {
    &:not(:first-of-type) {
      padding-top: 37px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0 16px;
    flex: 1;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.captchaAndSubmitContainer {
  @extend %fieldset;

  border-top: 1px solid $grey92;
  padding-top: 30px;
  padding-bottom: 35px;
  text-align: center;

  &:not(.captchaAndSubmitContainerSingleColumn) {
    @include media-breakpoint-only(md) {
      @include sidePadding(164px);
    }
    @include media-breakpoint-only(lg) {
      @include sidePadding(207px);
    }
    @include media-breakpoint-up(xl) {
      @include sidePadding(240px);
    }
  }
}

.submitButton {
  height: 50px;
  width: 100%;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-only(md) {
    width: 290px;
  }
  @include media-breakpoint-only(lg) {
    width: 372px;
  }
  @include media-breakpoint-up(xl) {
    width: 440px;
  }
}
