@import "css-variables/breakpoints";
@import "css-variables/colors";

.sidebarContainer {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 4px 18px 23px 0 $transparentBlack13;

  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }
}

.bannerContainer {
  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @include media-breakpoint-down(xs) {
    padding-left: 6%;
    padding-right: 6%;
  }
  @include media-breakpoint-only(sm) {
    padding-left: 20%;
    padding-right: 20%;
  }
  @include media-breakpoint-only(md) {
    padding-left: 13%;
    padding-right: 13%;
  }
  @include media-breakpoint-up(lg) {
    padding: 50px 15%;
  }
}

.bannerImage {
  @include media-breakpoint-down(sm) {
    margin-bottom: 17px;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 10px;
  }
}

.bannerTitle {
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    margin-bottom: 0;
  }
  @include media-breakpoint-only(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.bannerDescription {
  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 18px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 25px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 47px;
  }
}

.bannerButton {
  font-size: 12px;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
  @include media-breakpoint-only(md) {
    width: 93%;
  }
  @include media-breakpoint-up(lg) {
    width: 95%;
  }

  @include media-breakpoint-up(md) {
    padding-left: 5px;
    padding-right: 5px;
  }
}
