@import "css-variables/colors";
@import "css-variables/mixins/dropdown";

.container {
  position: relative;
}

.dropdown {
  @include dropdown;

  width: 180px;
}

.button {
  padding-left: 16px;

  &.isActive {
    box-shadow: inset 0 0 0 2px $secondary;
    color: $grey;

    &:hover,
    &:focus-visible {
      box-shadow: inset 0 0 0 2px $secondaryDark;
      color: $grey11;
    }
  }
}

.arrowIcon {
  color: $secondary;

  .button:hover &,
  .button:focus-visible & {
    color: $secondaryDark;
  }
}
