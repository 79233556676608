@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-down(xs) {
    margin-left: 19px;
  }
  @include media-breakpoint-only(sm) {
    margin-left: 27px;
  }
  @include media-breakpoint-down(sm) {
    height: 40px;
  }
  @include media-breakpoint-up(md) {
    height: 72px;
    margin-right: -20px;
  }
}

.dropdown {
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba($darkBlue, .08);
}
