@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  margin: 40px auto;

  @include media-breakpoint-up(sm) {
    align-items: center;
    display: flex;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.imageContainer {
  @include media-breakpoint-down(xs) {
    min-width: 100%;
  }
  @include media-breakpoint-only(sm) {
    height: 122px;
    min-width: 186px;
  }
  @include media-breakpoint-only(md) {
    height: 170px;
    min-width: 211px;
  }
  @include media-breakpoint-only(lg) {
    height: 190px;
    min-width: 271px;
  }
  @include media-breakpoint-up(xl) {
    height: 224px;
    min-width: 320px;
  }
}

.textContainer {
  @include media-breakpoint-only(sm) {
    padding-left: 20px;
  }
  @include media-breakpoint-only(md) {
    padding-left: 32px;
  }
  @include media-breakpoint-only(lg) {
    padding-left: 38px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 40px;
  }
}

.title {
  color: $grey11;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    margin-top: 20px;
  }
}

.description {
  color: $grey50;
  line-height: 22px;
}
