@import "css-variables/mixins/form";

.container {
  position: relative;
  height: 50px;
}

.input {
  @include formField;
}
