@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  border-bottom: 1px solid $grey92;
  position: relative;

  @include media-breakpoint-down(xs) {
    padding-bottom: 28px;
  }
  @include media-breakpoint-only(sm) {
    padding-bottom: 34px;
  }
  @include media-breakpoint-only(md) {
    padding-bottom: 40px;
  }
  @include media-breakpoint-only(lg) {
    padding-bottom: 53px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 60px;
  }
}

.sectionTitle {
  font-weight: 700;
  margin-bottom: 25px;
  margin-top: 50px;
}

.sectionImage {
  max-width: 100%;
}

.downloadButton {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
  width: auto;
}
