@import "css-variables/breakpoints";
@import "css-variables/colors";

.cookieSettingsBarContainer {
  display: none;

  &.barOpen {
    align-items: center;
    background-color: rgba(60, 60, 60, .9);
    bottom: 0;
    display: flex;
    font-weight: 700;
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: 999;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}

.cookieSettingsBarMainText {
  color: $white;
  flex-grow: 1;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
  @include media-breakpoint-up(sm) {
    padding-right: 30px;
  }
}

.cookieSettingsBarLink {
  white-space: nowrap;
}

.cookieSettingsBarBntPrimary,
.cookieSettingsBarBntSecondary {
  cursor: pointer;
  padding: 7px 10px;
  white-space: nowrap;

  @include media-breakpoint-down(xs) {
    margin-top: 10px;
  }
  @include media-breakpoint-up(sm) {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.cookieSettingsBarBntPrimary {
  background-color: $lightGreen;
  border: 1px solid $lightGreen;
  color: $grey20;
  min-width: 150px;
}

.cookieSettingsBarBntSecondary {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
}

.cookieSettingsBarBntClose {
  color: $white;
  cursor: pointer;
  font-size: 42px;
  height: 35px;
  line-height: 0.7;
  width: 35px;

  @include media-breakpoint-down(xs) {
    order: -1;
  }

  &:hover {
    opacity: 0.8;
  }
}
