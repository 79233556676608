@import "css-variables/breakpoints";
@import "css-variables/colors";

.imagesContainer {
  position: relative;

  @include media-breakpoint-down(xs) {
    height: 350px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-only(sm) {
    height: 400px;
  }
  @include media-breakpoint-only(md) {
    height: 560px;
  }
  @include media-breakpoint-only(lg) {
    height: 780px;
  }
  @include media-breakpoint-up(xl) {
    height: 860px;
    max-width: 720px;
  }
}

.cardsContainer {
  @include media-breakpoint-only(sm) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-top: 20px;
    order: 1;
  }
}
