@import "css-variables/colors";
@import "css-variables/fonts";
@import "css-variables/breakpoints";

.navBar {
  @include media-breakpoint-down(sm) {
    background-color: $white;
    bottom: 0;
    display: flex;
    left: 0;
    overflow-y: auto;
    padding-bottom: 90px;
    position: fixed;
    right: 0;
    top: 80px;
  }
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.navBarHidden {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.menu {
  @include media-breakpoint-down(sm) {
    margin: auto;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    height: 100%;
    justify-content: flex-end;
  }
}

.menuItem {
  text-align: center;

  @include media-breakpoint-down(sm) {
    border-bottom: 2px solid $grey96;
    padding: 32px 0 33px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.menuLink {
  @include font-helvetica-bdcn;

  text-transform: uppercase;
  transition: color .2s, background-color .2s;

  @include media-breakpoint-down(sm) {
    color: $grey;
    display: block;
    font-size: 22px;
    letter-spacing: .6px;
  }
  @include media-breakpoint-up(md) {
    align-items: center;
    color: $white;
    display: flex;
    height: 100%;
    padding: 5px 20px;

    &:hover {
      background-color: $grey92;
    }

    &.isInverted {
      color: $grey;
    }
  }
  @include media-breakpoint-between(md, lg) {
    font-size: 12px;
    letter-spacing: .2px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 14px;
    letter-spacing: .5px;
  }

  &.activeLink {
    color: $green;
  }

  &:hover {
    color: $grey11;
  }
}

.hamburgerBtn {
  @include media-breakpoint-down(sm) {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 16px;
    padding: 0;
    position: relative;
    width: 20px;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hamburgerElement {
  @include media-breakpoint-down(sm) {
    top: 50%;
    transform: translateY(-50%);

    &,
    &::before,
    &::after {
      background-color: $green;
      border-radius: 1.5px;
      height: 2px;
      position: absolute;
      transition: transform .2s;
      width: 100%;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      transform: translateY(-7px);
    }

    &::after {
      transform: translateY(7px);
    }

    &.isActive {
      background-color: transparent;

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
