@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  color: $grey;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 60px;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 50px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 82px;
  }
}

.sidebarWrapper {
  @include media-breakpoint-only(sm) {
    margin-left: 8.3333%;
    margin-right: 8.3333%;
  }
}

.title {
  color: $grey11;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 8px;
  }
  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 42px;
    margin-top: 13px;
  }
}
