@import "css-variables/colors";

.container {
  height: 100%;
  pointer-events: auto;
  position: relative;

  &.withFullscreen {
    cursor: pointer;
  }
}

.fullscreenContainer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.fullscreenCarouselImageWrapper {
  background-color: rgba(0, 0, 0, .8);
}

.fullscreenCarouselImage {
  object-fit: contain;
}

.closeButtonWrapper {
  background-color: $grey11;
  left: 0;
  padding: 10px 3%;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
  z-index: 1;
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 16px;
  padding: 0;
  width: 16px;

  &:hover > .closeButtonIcon,
  &:focus > .closeButtonIcon {
    color: $green;
  }
}

.closeButtonIcon {
  color: $white;
  transition: color .2s;
}
