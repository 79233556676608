@import "css-variables/colors";

@mixin widgetItem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.shareButton {
  @include widgetItem;
  border: 0;
  background-color: $grey92;
  cursor: pointer;
  padding: 0;
  transition: background-color .2s;

  &:hover{
    background-color: $grey85;
  }

  &.shareButton_active {
    background-color: transparent;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

.socialLink {
  @include widgetItem;
  visibility: hidden;

  &.socialLink_visible {
    visibility: visible;
  }
}
