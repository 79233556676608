@import "css-variables/colors";
@import "css-variables/breakpoints";

.successMessage {
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding: 40px 20px;
  }
  @include media-breakpoint-only(sm) {
    padding: 50px 50px 30px;
  }
  @include media-breakpoint-only(md) {
    padding: 40px 80px 45px;
  }
  @include media-breakpoint-only(lg) {
    padding: 50px 105px 45px;
  }
  @include media-breakpoint-up(xl) {
    padding: 50px 120px 45px;
  }
}

.thanksText {
  color: $grey11;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 20px;
  }
}

.detailsText {
  color: $grey55;
  font-size: 14px;

  @include media-breakpoint-down(xs) {
    line-height: 22px;
  }
  @include media-breakpoint-up(sm) {
    line-height: 18px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 80px;
  }
  @include media-breakpoint-only(sm) {
    margin-bottom: 145px;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 170px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 215px;
  }
}

.twitterIcon {
  background-repeat: no-repeat;
  background-size: cover;
  color: $twitterBlue;
  height: 34px;
  margin: 0 auto;
  width: 42px;
}

.twitterLink {
  color: $grey;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 15px;

  &:active,
  &:visited {
    color: $grey;
  }

  &:hover {
    color: $grey20;
  }
}
