@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  align-items: center;
  border-bottom: 2px solid $grey30;
  overflow: hidden; // to remove scroll on mobile when awards weren't loaded
  padding-bottom: 20px;
  padding-top: 20px;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.partText {
  color: $grey85;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-size: 11px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
}

.logoText {
  color: $white;
  letter-spacing: 0.8px;

  @include media-breakpoint-down(xs) {
    font-size: 20px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 27px;
  }
}

.awardsContainer {
  align-items: center;
  display: flex;

  @include media-breakpoint-down(xs) {
    margin-top: 6px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 27px;
  }
}

.awardImage {
  height: auto;
  width: auto;

  @include media-breakpoint-down(xs) {
    height: 50px;
    max-width: 108px;
  }
  @include media-breakpoint-up(sm) {
    max-width: 129px;

    &:first-child {
      height: 77px;
      width: 99px;
    }
    &:nth-child(2) {
      height: 60px;
    }
    &:nth-child(n+3) {
      height: 70px;
    }
  }

  @include media-breakpoint-down(xs) {
    &:first-child {
      margin-left: -8px;
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-right: 11px;
    }
    &:nth-child(n+3):not(:last-child) {
      margin-right: 21px;
    }
  }
  @include media-breakpoint-only(sm) {
    &:first-child {
      margin-right: -12px;
    }
    &:nth-child(2) {
      margin-right: -1px;
    }
    &:nth-child(n+3):not(:last-child) {
      margin-right: 6px;
    }
  }
  @include media-breakpoint-up(md) {
    &:first-child {
      margin-right: 2px;
    }
    &:nth-child(2) {
      margin-right: 11px;
    }
    &:nth-child(n+3):not(:last-child) {
      margin-right: 21px;
    }
  }
}
