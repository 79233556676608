@import "css-variables/colors";

.validationError {
  bottom: -21px;
  color: $red;
  font-size: 11px;
  line-height: 16px;
  padding: 0 15px;
  position: absolute;
}
