@import "css-variables/colors";
@import "css-variables/breakpoints";

.container {
  border-top: 2px solid $grey96;
  text-align: center;

  @include media-breakpoint-down(sm) {
    padding-bottom: 40px;
    padding-top: 30px;
  }
  @include media-breakpoint-only(md) {
    padding-bottom: 35px;
    padding-top: 25px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 60px;
    padding-top: 35px;
  }
}

.mainTitle {
  color: $grey11;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-size: 25px;
    line-height: 36px;
  }
  @include media-breakpoint-only(sm) {
    font-size: 30px;
    line-height: 45px;
  }
  @include media-breakpoint-up(md) {
    font-size: 42px;
    line-height: 64px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 5px;
  }
}

.secondaryTitle {
  font-size: 16px;
  line-height: 28px;
}

.signUpButton {
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  letter-spacing: 0;
  margin: 25px auto 0;
  position: relative;
  justify-content: flex-start;

  @include media-breakpoint-down(xs) {
    min-width: 215px;
  }
  @include media-breakpoint-only(sm) {
    min-width: 190px;
  }
  @include media-breakpoint-only(md) {
    min-width: 195px;
  }
  @include media-breakpoint-up(lg) {
    min-width: 170px;
  }
}
