@import "css-variables/breakpoints";

.container {
  padding-top: 7px;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
  }
  @include media-breakpoint-between(md, lg) {
    right: 40px;
    width: calc(25% - 45px);
  }
  @include media-breakpoint-up(xl) {
    right: 100px;
    width: calc(25% - 80px);
  }
}
