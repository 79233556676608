@import "css-variables/breakpoints";
@import "css-variables/colors";

.image {
  box-shadow: 14px 38px 23px 0 $transparentBlack13;
}

.industry {
  height: 100%;

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }
  @include media-breakpoint-between(md, lg) {
    padding: 30px;
  }
  @include media-breakpoint-up(xl) {
    padding: 40px;
  }

  > img {
    opacity: .5;
  }
}

.industryTitle {
  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 24px;
  }
  @include media-breakpoint-only(md) {
    font-size: 22px;
    line-height: 30px;
    width: 220px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 26px;
    width: 255px;
  }
  @include media-breakpoint-only(lg) {
    line-height: 30px;
  }
  @include media-breakpoint-up(xl) {
    line-height: 34px;
  }
}
