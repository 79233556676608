@import "css-variables/colors";

.wrapper {
  height: 100%;
  min-width: 100%;
  position: relative;
}

.image {
  pointer-events: none;
  position: absolute;
}
