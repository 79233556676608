@import "css-variables/breakpoints";
@import "css-variables/colors";

.button {
  background-color: $grey11;
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  opacity: 0;
  position: fixed;
  transition: opacity .5s, background-color .2s;
  visibility: hidden;
  width: 50px;
  z-index: 8;

  @include media-breakpoint-down(xs) {
    bottom: 15px;
    right: 15px;
  }
  @include media-breakpoint-only(sm) {
    bottom: 25px;
    right: 25px;
  }
  @include media-breakpoint-up(md) {
    bottom: 50px;
    right: 50px;
  }

  &:hover {
    background-color: $green;
  }

  &::after {
    border-color: $white;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 10px;
    left: 20px;
    position: absolute;
    top: 20px;
    transform: rotate(-45deg);
    width: 10px;
  }
}

.isVisible {
  opacity: 1;
  visibility: visible;
}

.isHiddenOnMobile {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.isHiddenOnTablet {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}
