@import "css-variables/colors";

.container {
  cursor: pointer;
  display: flex;
}

.checkbox {
  background-color: $white;
  border: solid 2px $grey85;
  border-radius: 2px;
  height: 16px;
  line-height: 11px;
  margin-top: 1px;
  text-align: center;
  transition: border-color .2s, background-color .2s;
  width: 16px;

  .container:hover &:not(.isActive) {
    border-color: $grey61;
  }

  &.isActive {
    background-color: $green;
    border-color: $green;
  }
}

.checkboxIcon {
  color: $white;
  height: 7px;
  vertical-align: middle;
  width: 10px;
}

.text {
  margin-left: 8px;
}
