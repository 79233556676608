@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-between(md, lg) {
    border-bottom: 2px solid $grey96;
    padding-bottom: 2px;
  }
}

.row {
  @include media-breakpoint-up(xl) {
    border-bottom: 2px solid $grey96;
    padding-bottom: 2px;
  }
}
