@import "css-variables/colors";
@import "css-variables/breakpoints";

@mixin sidePadding($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

.fieldset {
  @include media-breakpoint-down(xs) {
    @include sidePadding(20px);
  }
  @include media-breakpoint-only(sm) {
    @include sidePadding(52px);
  }
  @include media-breakpoint-only(md) {
    @include sidePadding(81px);
  }
  @include media-breakpoint-only(lg) {
    @include sidePadding(103px);
  }
  @include media-breakpoint-up(xl) {
    @include sidePadding(120px);
  }
}

.captchaAndSubmitFieldset {
  @extend .fieldset;

  border-top: 1px solid $grey92;

  @include media-breakpoint-down(sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.submitButton {
  height: 50px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: 35px;
  }
  @include media-breakpoint-up(md) {
    margin-top: 45px;
  }
}
