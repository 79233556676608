@import "css-variables/breakpoints";
@import "css-variables/colors";

.footer {
  background-color: $grey;
  margin-top: auto;
  position: relative;
  transform: translateZ(0);
}

.sectionContainer {
  border-bottom: 2px solid $grey30;
}

.langSwitcherWrapper {
  padding-bottom: 20px;
  padding-top: 20px;

  @include media-breakpoint-up(sm) {
    border-bottom: 2px solid $grey30;
  }
}

.menuWrapper {
  @include media-breakpoint-down(xs) {
    padding-bottom: 8px;
    padding-top: 23px;
  }
  @include media-breakpoint-only(sm) {
    padding-bottom: 62px;
    padding-top: 44px;
  }
  @include media-breakpoint-only(md) {
    padding-bottom: 64px;
    padding-top: 43px;
  }
  @include media-breakpoint-only(lg) {
    padding-bottom: 46px;
    padding-top: 54px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 77px;
    padding-top: 53px;
  }
}

.menuTitle {
  min-height: 37px;

  @include media-breakpoint-only(sm) {
    margin-bottom: 13px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 7px;
  }
}

.menuTitleLink {
  color: $white;
  letter-spacing: 0.4px;
  line-height: 18px;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }

  &:hover {
    color: $grey61;
  }
}

.menuList {
  @include media-breakpoint-down(xs) {
    margin-bottom: 51px;
  }
}

.menuListItem {
  @include media-breakpoint-down(sm) {
    margin-bottom: 8px;
  }
  @include media-breakpoint-between(md, lg) {
    margin-bottom: 10px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 12px;
  }
}

.menuListItemLink {
  color: $grey61;

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: 12px;
  }
  @include media-breakpoint-down(lg) {
    font-weight: 700;
  }
  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }

  &:hover,
  &:focus {
    color: $white;
  }
}

.copyright {
  @include media-breakpoint-down(lg) {
    font-size: 10px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 12px;
  }
}

.copyright {
  color: $white;
  padding-bottom: 24px;
  padding-top: 23px;
}
