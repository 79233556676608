@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-down(xs) {
    padding-bottom: 10px;
    padding-top: 70px;
  }
  @include media-breakpoint-only(sm) {
    padding-top: 85px;
  }
  @include media-breakpoint-between(md, lg) {
    padding-top: 100px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 150px;
  }
}
