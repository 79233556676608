@import "css-variables/breakpoints";
@import "css-variables/colors";

.pagination {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  line-height: 1.2;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding-bottom: 50px;
  }
  @include media-breakpoint-between(sm, md) {
    padding-bottom: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 50px;
  }

  a {
    display: block;
  }
}

.paginationItem,
.paginationDotsItem {
  @include media-breakpoint-down(xs) {
    margin-left: 7px;
    margin-right: 7px;
  }
  @include media-breakpoint-up(sm) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.paginationDotsItem,
.paginationLink {
  @include media-breakpoint-down(xs) {
    width: 40px;
  }
  @include media-breakpoint-up(sm) {
    width: 50px;
  }
}

.paginationLink {
  color: $grey;
  padding-left: 5px;
  padding-right: 5px;

  @include media-breakpoint-down(xs) {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  @include media-breakpoint-up(sm) {
    padding-bottom: 17px;
    padding-top: 17px;
  }

  &:hover {
    color: $green;
  }
}

.paginationLinkActive {
  background-color: $grey85;
  border-radius: 4px;

  &,
  &:hover {
    color: $white;
  }
}

.paginationItemArrowPrev {
  @include media-breakpoint-down(xs) {
    margin-right: 16px;
  }
  @include media-breakpoint-only(sm) {
    margin-right: 75px;
  }
  @include media-breakpoint-only(md) {
    margin-right: 55px;
  }
  @include media-breakpoint-up(lg) {
    margin-right: 70px;
  }
}

.paginationItemArrowNext {
  @include media-breakpoint-down(xs) {
    margin-left: 16px;
  }
  @include media-breakpoint-only(sm) {
    margin-left: 75px;
  }
  @include media-breakpoint-only(md) {
    margin-left: 55px;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 70px;
  }
}

.paginationArrow {
  color: $green;
  margin-top: 2px;
  transition: color .2s;

  @include media-breakpoint-down(xs) {
    height: 24px;
    width: 15px;
  }
  @include media-breakpoint-up(sm) {
    height: 38px;
    width: 23px;
  }

  &:hover {
    color: $grey;
  }
}

.paginationArrowNext {
  transform: rotate(180deg);
}

.paginationArrowDisabled {
  &,
  &:hover {
    color: $grey92;
  }
}
