@import "css-variables/breakpoints";
@import "css-variables/colors";

.wrapper {
  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: 40px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 50px;
  }
}

.link {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 3px 0 $transparentBlack15;
  color: $grey;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: 10px 12px 23px 0 $transparentBlack15;
  }
}

.imageContainer {
  background-color: $grey11;
  @include media-breakpoint-down(xs) {
    height: 210px;
  }
  @include media-breakpoint-between(sm, md) {
    height: 180px;
  }
  @include media-breakpoint-only(lg) {
    height: 220px;
  }
  @include media-breakpoint-up(xl) {
    height: 235px;
  }
}

.image {
  opacity: .7;
  transition: opacity .2s;

  .link:hover & {
    opacity: 1;
  }
}

.textContainer {
  background-color: $white;
  padding-bottom: 30px;
  padding-top: 30px;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.title {
  color: $grey;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 15px;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}

.description {
  color: $grey50;
  font-size: 14px;
  line-height: 22px;
}

.mark {
  background-color: $white;
  border-radius: 12px;
  color: $green;
  font-size: 9px;
  font-weight: 700;
  height: 25px;
  left: 10px;
  line-height: 15px;
  padding: 5px 15px;
  position: absolute;
  text-transform: uppercase;
  top: 10px;
  z-index: 1;
}
