@import "css-variables/breakpoints";

.container {
  margin-top: 70px;

  @include media-breakpoint-down(xs) {
    height: 260px;
    margin-left: -20px;
    margin-right: -20px;
  }
  @include media-breakpoint-only(sm) {
    margin-left: -10.4%;
    margin-right: -10.4%;
  }
  @include media-breakpoint-between(sm, lg) {
    height: 400px;
  }
  @include media-breakpoint-up(xl) {
    height: 450px;
  }
}

.arrowLeft {
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, .4));
}

.arrowRight {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, .4));
}

.dots {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .4));

  @include media-breakpoint-down(md) {
    height: 60px;
    padding-top: 24px;
  }
  @include media-breakpoint-up(lg) {
    height: 70px;
    padding-top: 28px;
  }
}
