@import 'css-variables/colors';

$bg-color: var(--warning-bar-bg-color, $secondary);
$text-color: var(--warning-bar-text-color, $white);

.container {
  background-color: $bg-color;
  display: flex;
  font-size: 12px;
  line-height: 1.5;
  padding-bottom: 16px;
  padding-top: 16px;
}

.text {
  align-items: center;
  color: $text-color;
  display: flex;
  margin-right: 20px;
}

.title {
  font-weight: 700;
  margin-right: 10px;
}

.button {
  color: $text-color;
  font-size: 12px;
  margin-left: auto;
  white-space: nowrap;
  transition: opacity .2s;

  &:not([disabled]) {
    &:hover,
    &:focus-visible {
      color: $text-color;
      opacity: 0.6;
    }
  }
}
