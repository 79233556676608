@import "../colors";

@mixin dropdown {
  background-color: $white;
  border: 1px solid $grey92;
  border-radius: 4px;
  box-shadow: 0 12px 24px rgba($black, .1);
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;

  &.isHidden {
    display: none;
  }
}

@mixin dropdownItem {
  align-items: center;
  color: $grey;
  display: flex;
  min-height: 50px;
  line-height: 18px;
  outline: none;
  overflow: hidden;
  padding: 0 15px;
  text-overflow: ellipsis;
  transition: color .2s, background-color .2s;

  &:not(.isEmpty) {
    cursor: pointer;

    &:hover,
    &:focus-visible,
    &.isFocused,
    &.isSelected {
      color: $grey11;
    }

    &:hover,
    &:focus-visible,
    &.isFocused {
      background-color: $grey92;

      > svg {
        color: $secondaryDark;
      }
    }

    &.isSelected {
      font-weight: 700;
    }

    &.isDisabled {
      color: $grey61;
      pointer-events: none;
    }
  }
}

@mixin dropdownItemTickIcon {
  color: $secondary;
  flex-shrink: 0;
  height: 12px;
  margin-left: auto;
  margin-right: 4px;
  transition: color .2s;
  width: 16px;
}
