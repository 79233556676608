@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.image {
  border-radius: 50%;
  height: 100px;
  width: 100px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 15px;
  }
}

.title {
  color: $grey;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 15px;
  width: 100%;
}

.description {
  color: $grey50;
  font-size: 14px;
  line-height: 22px;
  width: 100%;

  @include media-breakpoint-down(xs) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-between(sm, md) {
    margin-bottom: 25px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
  }
}

.button {
  @include media-breakpoint-down(lg) {
    min-width: 180px;
  }
  @include media-breakpoint-up(xl) {
    min-width: 215px;
  }
}
