@import "css-variables/colors";
@import "css-variables/breakpoints";

.lockedContainer {
  background-color: $grey85;
  pointer-events: none;
  user-select: none;

  > * {
    opacity: 0.3;
  }
}

.mainFieldset {
  @include media-breakpoint-down(sm) {
    padding-bottom: 40px;
    padding-top: 36px;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 47px;
    padding-top: 50px;
  }
}

.rememberMeField {
  margin-top: 20px;
}

.warningMessage {
  text-align: center;
}

.warningButtonsContainer {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
  }
}

.acceptButton,
.declineButton {
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.acceptButton {
  @include media-breakpoint-up(md) {
    margin-right: 20px;
  }
}

.declineButton {
  background-color: $grey61;
}
