@import "css-variables/colors";
@import "css-variables/breakpoints";

$elementsCount: 7;
$xsOrders: (
  2: 7,
  4: 2,
  6: 4,
  7: 6,
);
$smOrders: (
  3: 4,
  4: 5,
  5: 7,
  6: 3,
  7: 6,
);

@function get-current-order($index, $orders) {
  $current-order: map-get($orders, $index);

  @return if(not $current-order, $index, $current-order);
}

@mixin elements-order($orders) {
  @for $i from 1 through $elementsCount {
    &:nth-child(#{$i}) {
      order: get-current-order($i, $orders);
    }
  }
}

.additionalLinks {
  padding-top: 16px;

  @include media-breakpoint-only(sm) {
    width: 77.5%;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 24px;
  }
  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 14px;
  }
}

.additionalLinksItem {
  @include media-breakpoint-down(sm) {
    order: $elementsCount + 1; // in case there will be more elements than $elementsCount
  }
  @include media-breakpoint-down(xs) {
    @include elements-order($xsOrders);
  }
  @include media-breakpoint-only(sm) {
    @include elements-order($smOrders);
  }

  @include media-breakpoint-up(md) {
    margin-right: 7px;
    padding-left: 0;
    padding-right: 0;

    &::after {
      color: $grey61;
      content: '|';
      margin-left: 7px;
    }

    &:last-child::after {
      display: none;
    }
  }
}

.additionalLinksItemLink {
  color: $grey61;
  cursor: pointer;
  font-weight: 700;
  line-height: 26px;

  &:hover,
  &:focus {
    color: $white;
  }
}

.additionalLinks {
  @include media-breakpoint-down(lg) {
    font-size: 10px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 12px;
  }
}
